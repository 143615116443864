<template>
  <b-container fluid>
    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>Editar Mantenimiento</b></h4>
            </template>
            <div class="row px-5 mt-3">
              <div class='col-md-6'>
                <label for="lugar" class="form-label">Lugar</label>
                <b-form-select id="lugar" :options="ListaLugar" v-model='item.LugarId' required value-field="id"
                  text-field="nombre">
                  <template #first>
                    <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                      --</b-form-select-option>
                  </template></b-form-select>
              </div>
              <div class='col-md-6 mt-3 mt-md-0'>
                <label for="zona" class="form-label">Zona afectada</label>
                <MultiSelect id='zona' :options="ListaZona" v-model='zonas' :selected='zonasdefault' valorGuardar="id"
                  valorVer="nombre" emptyList="¡Lo sentimos!. No tienes opciones." :selectFirst="false"
                  :allData="false" />
              </div>
              <div class='col-md-6 mt-3'>
                <label for="tipo" class="form-label">Tipo de mantenimiento</label>
                <b-form-select id="tipo" v-model='item.TipoMantenimientoId' :options="ListaTipoMantenimiento" required
                  value-field="id" text-field="descripcion">
                  <template #first>
                    <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                      --</b-form-select-option>
                  </template></b-form-select>
              </div>
              <div class='col-md-6 mt-3'>
                <label for="sucursal" class="form-label">Sucursal</label>
                <b-form-select id='sucursal' @change='selectSucursal(item.SucursalId)' v-model='item.SucursalId'
                  required text-field="descripcion" value-field="id" :options="listaSucursales">
                  <template #first>
                    <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                      --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class='col-md-6 mt-3' v-if='usuario.rol.nivel != 8'>
                <label for="departamento" class="form-label">Departamento</label>
                <b-form-select id='departamento' required value-field="id" text-field="descripcion"
                  @change='selectDepartamento(item.DepartamentoId)' v-model='item.DepartamentoId'
                  :options="listaDepartamentos">
                  <template #first>
                    <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                      --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class='col-md-6 mt-3' v-if='usuario.rol.nivel != 8'>
                <label for="area" class="form-label">Área</label>
                <b-form-select id='area' @change='selectArea(item.AreaId)' v-model='item.AreaId' required
                  text-field="descripcion" value-field="id" :options="listArea">
                  <template #first>
                    <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                      --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class='col-md-6 mt-3' v-if='usuario.rol.nivel != 8'>
                <label for="cargo" class="form-label">Cargo</label>
                <b-form-select id="cargo" @change='selectCargo(item.CargoId)' v-model='item.CargoId'
                  :options="listCargos" required value-field="id" text-field="nombre">
                  <template #first>
                    <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                      --</b-form-select-option>
                  </template></b-form-select>
              </div>
              <div class='col-md-6 mt-3' v-if='usuario.rol.nivel != 8'>
                <label for="solicitante" class="form-label">Solicitante</label>
                <b-form-select id="solicitante" :options="listaPersonas" required value-field="id"
                  v-model='item.SolicitanteId' text-field="nombreCompleto">
                  <template #first>
                    <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                      --</b-form-select-option>
                  </template></b-form-select>
              </div>
              <div class='col-md-6 mt-3' v-if='usuario.rol.nivel == 8'>
                <label for="tecnico" class="form-label">Técnico asignado</label>
                <b-form-select id="tecnico" :options="ListasTecnicos" v-model='item.TecnicoId' required value-field="id"
                  text-field="nombre"><template #first>
                    <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                      --</b-form-select-option>
                  </template></b-form-select>
              </div>
              <div class="col-md-6 mt-3">
                <div class="mb-3">
                  <label for="prioridad" class="form-label">Prioridad</label>
                  <b-form-select id="prioridad" :options="ListaPrioridad" required v-model="item.Prioridad"
                    value-field="id" text-field="nombre"><template #first>
                      <b-form-select-option selected :value="null" disabled>-- selecciona una opción
                        --</b-form-select-option>
                    </template></b-form-select>
                </div>
              </div>
            </div>
            <div class="row px-5 mt-3">
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="Descripcion" class="form-label">Descripción del problema</label>
                  <textarea required v-model="item.Descripcion" placeholder="Escriba la descripción del problema..."
                    class="form-control" id="Descripcion" rows="6"></textarea>
                </div>
              </div>
            </div>
            <div class="row px-5 mt-3">
              <div class="col-md-6 mt-3">
                <label for="file-upload" class="form-label d-block">Adjuntar archivos</label>
                <input id="file-upload" type="file" multiple @change="handleFileUpload" />
              </div>
            </div>
            <div class="text-right px-5 pb-4">
              <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Enviar</button>
              <button @click.prevent="$router.push('/helex/Erp/Mantenimientos')"
                class="mx-1 btn px-4 py-2 btn-secondary">Cancelar</button>
            </div>
          </card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>

  const MultiSelect = () => import("../../../../components/MultiSelect/MultiSelect.vue");
  const Swal = require("sweetalert2");
  import _ from "lodash";

  export default {
    components: {
      MultiSelect,
    },
    data() {
      return {
        ListaPrioridad: [
          { id: 1, nombre: "Baja" },
          { id: 2, nombre: "Media" },
          { id: 3, nombre: "Alta" },
        ],
        listaDepartamentos: [],
        ListaLugar: [],
        ListaZona: [],
        ListaTipoMantenimiento: [],
        ListasTecnicos: [],
        listArea: [],
        listaPersonas: [],
        listaSucursales: [],
        listCargos: [],
        usuario: {},
        zonas: null,
        zonasdefault: null,
        item: {
          creadorId: null,
          LugarId: null,
          TipoMantenimientoId: null,
          Estado: 0,
          SucursalId: null,
          DepartamentoId: null,
          FechaCreacion: null,
          AreaId: null,
          CargoId: null,
          SolicitanteId: null,
          Descripcion: null,
          TecnicoId: null,
          Prioridad: null,
          EmpresaId: null,
          UrlAdjunto: null,
        },
        filess: [],
        // item: {
        //   General: {
        //     Id: null,
        //     Sistema: null,
        //     Tipo: null,
        //     Departamento: null,
        //     Solicitante: null,
        //     Sucursal: null,
        //     Cargo: null,
        //     Area: null,
        //     Tecnico: null,
        //     Progreso: null,
        //     Prioridad: null,
        //     EmpresaId: null,
        //     PersonaId: null,
        //     urlAdjunto: null,
        //     Mensaje: null,
        //   },
        //   Detail: { Id: null, ManteniminetoId: null, Fecha: null, Leido: false },
        // },
        // files: null,
        // filelist: [],
        // file1: null,
        // filename1: null,
      };
    },
    async mounted() {
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getSucursalEmpresa(this.usuario.empresa.id);
      await this.getTecnico();
      await this.getTipoMantenimiento();
      await this.getLugares();
      await this.getZona();
      await this.getData();
    },
    methods: {
      handleFileUpload(e) {
        this.filess = Array.from(e.target.files);
      },
      quitarTildesYEspacios(input) {
        let sinTildes = _.deburr(input);
        let sinEspacios = sinTildes.replace(/\s+/g, "");
        return sinEspacios;
      },
      async getData() {
        let resMantenimiento = await this.$store.dispatch("hl_get", {
          path: `Mantenimiento/GetMantenimientoId/${this.$route.params.id}`,
        });

        let resZonaId = await this.$store.dispatch('hl_get', {
          path: `Mantenimiento/GetMantenimientosZonaId/${resMantenimiento.data.id}`
        });

        this.item.LugarId = resMantenimiento.data.lugar.id;
        this.item.TipoMantenimientoId = resMantenimiento.data.tipoMantenimiento.id;
        this.item.SucursalId = resMantenimiento.data.sucursal.id;
        this.item.DepartamentoId = resMantenimiento.data.departamento.id;
        this.item.AreaId = resMantenimiento.data.area.id;
        this.item.CargoId = resMantenimiento.data.cargo.id;
        this.item.SolicitanteId = resMantenimiento.data.solicitante.id;
        this.item.TecnicoId = resMantenimiento.data.tecnico.id;
        this.item.Prioridad = resMantenimiento.data.prioridad;
        this.item.Descripcion = resMantenimiento.data.descripcion;
        this.zonasdefault = resZonaId.data

        await this.getDepartmentoPaisList(resMantenimiento.data.sucursal.id);
        await this.getAreaDepartamento(resMantenimiento.data.departamento.id);
        await this.getCargos(resMantenimiento.data.area.id);
        await this.getPersonaUbicacion(resMantenimiento.data.cargo.id);
      },
      async selectSucursal(event) {
        await this.getDepartmentoPaisList(event);
      },
      async selectDepartamento(event) {
        await this.getAreaDepartamento(event);
      },
      async selectArea(event) {
        await this.getCargos(event);
      },
      async selectCargo(event) {
        await this.getPersonaUbicacion(event);
      },
      async getPersonaUbicacion(event) {
        try {
          this.listaPersonas = [];
          let res = await this.$store.dispatch("hl_get", {
            path: `PersonaUbicacion/ListPersonaCargo/${event}/${this.usuario.empresa.id}`,
          });
          res.map((item) => {
            this.listaPersonas.push({
              id: item.persona.id,
              nombreCompleto: `${item.persona.nombres !== null ? item.persona.nombres : ""} ${item.persona.segundoNombre !== null ? item.persona.segundoNombre : ""} ${item.persona.apellidos !== null ? item.persona.apellidos : ""} ${item.persona.segundoApellido !== null ? item.persona.segundoApellido : ""}`,
            });
          });
        } catch (error) {
          this.personaUbicacion = {};
          console.error("err", error.message);
        }
      },
      async getTecnico() {
        const res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/ListPersonaTecnico/" + this.usuario.empresa.id,
        });
        this.ListasTecnicos = res.data.map((item) => {
          return {
            id: item.id,
            nombre: `${item.nombres !== null ? item.nombres : ""} ${item.segundoNombre !== null ? item.segundoNombre : ""} ${item.apellidos !== null ? item.apellidos : ""} ${item.segundoApellido !== null ? item.segundoApellido : ""}`,
          };
        });
      },
      async getDepartmentoPaisList(id) {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "Departamento/ListDepartamentoBySucursal/" + id,
          });
          if (res.length > 0) {
            this.listaDepartamentos = res;
          } else {
            this.listaDepartamentos = [];
          }
        } catch (error) {
          this.listaDepartamentos = [];
        }
      },
      async getAreaDepartamento(id) {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "Area/ListAreaDepartamento/" + id,
          });
          this.listArea = res;
        } catch (error) {
          this.listArea = {};
          console.error("err", error);
        }
      },
      async getSucursalEmpresa(id) {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "Sucursal/ListSucursalEmpresa/" + id,
          });
          if (res.length > 0) {
            this.listaSucursales = res;
            await this.getCargos(id);
          } else {
            this.listaSucursales = [];
          }
        } catch (error) {
          this.listaSucursales = [];
          console.error("err", error);
        }
      },
      async getCargos(id) {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "Cargo/ListCargoArea/" + id,
          });
          this.listCargos = res;
        } catch (error) {
          this.listCargos = {};
          console.error("err", error);
        }
      },
      async getTipoMantenimiento() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "TipoMantenimiento/ObtenerTipoMantenimiento/" + this.usuario.empresa.id,
          });
          this.ListaTipoMantenimiento = res.data;
        } catch (error) {
          Swal.fire("Error!", "Ha ocurrido un error al obtener los Tipos de Mantenimiento", "error");
        }
      },
      async getLugares() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "Lugares/ObtenerLugares/" + this.usuario.empresa.id,
          });
          this.ListaLugar = res.data;
        } catch (error) {
          Swal.fire("Error!", "Ha ocurrido un error al obtener los Lugares", "error");
        }
      },
      async getZona() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: "Zonas/ObtenerZonas/" + this.usuario.empresa.id,
          });
          this.ListaZona = res.data;
        } catch (error) {
          Swal.fire("Error!", "Ha ocurrido un error al obtener las zonas", "error");
        }
      },
      async renameFile(nameFile) {
        this.realName = nameFile;
        let collectionBad = "á é í ó ú Á É Í Ó Ú ñ Ñ";
        let collectionGood = "a e i o u A E I O U n N";
        let collectionBadA = collectionBad.split(" ");
        let collectionGoodA = collectionGood.split(" ");
        let count = 0;
        for await (let it of collectionBadA) {
          let pos = nameFile.indexOf(it);
          if (pos > 0) {
            nameFile = nameFile.replaceAll(it, collectionGoodA[count]);
          }
          pos = 0;
          count++;
        }
        return nameFile;
      },
      async uploadImagenPerfilUrl(ticketId) {
        try {
          this.$isLoading(true);
          this.filename = await this.renameFile(this.file.name);
          if (this.filename != null) {
            let url = ".|site1|users|tickets|" + ticketId;

            let uploadImg = await this.$store.dispatch("onFileCustom", {
              filename: this.filename,
              file: this.file,
              url: url,
            });

            if (uploadImg == true) {
              return "https://www.helexiumfiles.com/users/tickets/" + ticketId + "/" + this.filename;
            }
          }
          this.$isLoading(false);
        } catch (error) {
          this.$isLoading(false);
          Swal.fire("Error!", "Ha ocurrido un error al guardar la foto.", "error");
          console.error("errror upload", error);
        }
      },

      async saveData() {
        try {
          this.$isLoading(true);
          this.item.creadorId = this.usuario.id;
          this.item.EmpresaId = this.usuario.empresa.id;
          this.item.FechaCreacion = this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDTHH:mm");

          let resMantenimiento = await this.$store.dispatch("hl_post", {
            path: "Mantenimiento/EditarMantenimiento/" + this.$route.params.id,
            data: this.item,
          })

          let resMantenimientoDetalle = await this.$store.dispatch("hl_post", {
            path: "MantenimientoDetalles/EditarMantenimientoDetalles/" + resMantenimiento.data,
            data: this.item.Descripcion
          })

          if (this.zonas.length > 0) {
            let saveZonas = [];

            this.zonas.map((item) => {
              let data = {
                mantenimientoId: resMantenimiento.data,
                zonaId: item,
              };
              saveZonas.push(data);
            });

            await this.$store.dispatch("hl_post", {
              path: `Mantenimiento/EditarMantenimientoZona/${resMantenimiento.data}`,
              data: saveZonas,
            })
          }

          if (this.filess.length === 0) {
            this.$isLoading(false);
            Swal.fire({
              title: "Mantenimiento",
              text: "Mantenimiento guardado correctamente",
              icon: "success",
              button: "Aceptar",
            }).then(() => {
              this.$router.push("/helex/Erp/Mantenimientos");
            })
            return
          }
          
          let urlImages = [];
          for (let i = 0; i < this.filess.length; i++) {
            let resurl = await this.onUpload(this.usuario.empresa.id, this.filess[i], this.filess[i].type.split("/")[1]);
            urlImages.push(resurl);
          }
          
          for (let i = 0; i < urlImages.length; i++) {
            let data = {
              MantenimientoDetallesId: resMantenimientoDetalle.data,
              ImageUrl: urlImages[i].url,
              NameUrl: urlImages[i].name,
            };
            await this.$store.dispatch("hl_post", {
              path: "MantenimientoDetalles/GuardarMantenimientoImagenes",
              data: data,
            })
          }
          
          this.$isLoading(false);
          Swal.fire({
            title: "Mantenimiento",
            text: "Mantenimiento Editado correctamente",
            icon: "success",
            button: "Aceptar",
          }).then(() => {
            this.$router.push("/helex/Erp/Mantenimientos");
          })

          // let dataGeneral = await this.$store.dispatch("hl_post", {
          //   path: "PersonaTicket/NuevoTicketGeneral",
          //   data: this.item.General,
          // });
          // if (dataGeneral > 0) {
          //   let dataCorreo = {
          //     Correo: "info@helexium.cloud",
          //     Asunto: "Nuevo ticket: " + this.item.General.Asunto,
          //     Mensaje: this.item.Detail.Mensaje,
          //   };
          //   let resCorreo = await this.$store.dispatch("hl_post", {
          //     path: "Persona/EnvioNotificacionCorreoCustom",
          //     data: dataCorreo,
          //   });

          //   console.log(resCorreo);
          //   this.item.Detail.TicketId = dataGeneral;
          //   this.item.urlAdjunto = await this.uploadImagenPerfilUrl(dataGeneral);

          //   let dataDetalle = await this.$store.dispatch("hl_post", {
          //     path: "PersonaTicket/NuevoTicketDetalle",
          //     data: this.item.Detail,
          //   });

          //   if (dataGeneral > 0 && dataDetalle > 0) {
          //     this.$isLoading(false);
          //     this.$router.push("/helex/Tickets");
          //     Swal.fire("Listo!", "Ticket creado correctamente!!", "success");
          //   }
          // } else {
          //   Swal.fire("Error!", "Intentalo nuevamente!!", "error");
          // }

        } catch (error) {
          this.$isLoading(false);
          Swal.fire({
            title: "Mantenimiento",
            text: "Error al editar el mantenimiento",
            icon: "error",
            button: "Aceptar",
          })
          console.error("error", error);
        }
      },
      async onUpload(empresaId, file, typeFile) {
        try {
          let nameFileT = this.$moment.tz(new Date(), 'America/Bogota').format('DDMMYYYYHHmmss') + Math.floor(Math.random() * (1 - 99999 + 1)) + 1;
          let dataGet;
          this.$isLoading(true);

          try {
            const responseGet = await this.$store.getters.fetchGet({
              path: "MantenimientoDetalles/ObtenerMantenimientoImagenes/" + empresaId,
            });
            dataGet = await responseGet.json();
          } catch {
            nameFileT = this.$moment.tz(new Date(), 'America/Bogota').format('DDMMYYYYHHmmss') + Math.floor(Math.random() * (1 - 99999 + 1)) + 1;
          }

          const newPath = this.quitarTildesYEspacios("empresa/" + this.usuario.empresa.id + "/mantenimiento/" + "Estado/0");

          const lastDirectory = newPath.substring(newPath.lastIndexOf("/") + 1);

          const filename = `${lastDirectory}_${nameFileT}.${typeFile}`;
          const urlImg = newPath.split("/").join("|");

          const fileModel = {
            filename: filename,
            file: file,
            url: ".|" + urlImg,
          };

          if (dataGet.data !== null) {
            if (dataGet.data.filter((item) => item.nameurl === filename).length !== 0) {
              Swal.fire({
                icon: "error",
                title: "Error!",
                text: `Ya existe un archivo con el nombre ${filename}`,
                confirmButtonText: "Aceptar",
              });
              return;
            }
          }

          let isSave = await this.$store.dispatch("onDropFile", fileModel);

          if (!isSave) {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Error al subir el archivo",
              confirmButtonText: "Aceptar",
            });
            return false;
          }

          return { url: "https://www.helexiumfiles.com/" + newPath + "/" + filename, name: filename };

        } catch (error) {
          console.error(error);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: error.message,
            confirmButtonText: "Aceptar",
          });
        } finally {
          this.loader = false;
        }
      }
    },
  };
</script>
